import React from "react";
import styles from './Input.module.scss';
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace } from "@fortawesome/free-solid-svg-icons";

interface IInputProps {
    onChange?: (v: any) => any;
    defaultValue?: string;
    width?: 'standard' | 'full';
    label?: string;
    name?: string;
    placeholder?: string;
    disabled?: boolean;
    maxLength?: number;
    type?: 'text'|'number';
    value?:string|number|null;
    className?: string;
    required?: boolean;
    showClearButton?: boolean;
    onClear?: () => void;
    spellCheck?: boolean;
    autoCorrect?: 'on' | 'off';
    autoCapitalize?: 'on' | 'off' | 'sentences' | 'words' | 'characters' | 'none';
}

export const Input: React.FC<IInputProps> = ({
                                                 name,
                                                 label,
                                                 placeholder,
                                                 disabled,
                                                 onChange,
                                                 defaultValue,
                                                 maxLength,
                                                 type,
                                                 value,
                                                 className,
                                                 required,
                                                 showClearButton,
                                                 onClear,
                                                 spellCheck,
                                                 autoCorrect,
                                                 autoCapitalize,
                                             }) => {
    const handleClear = () => {
        if (onClear) {
            onClear();
        } else if (onChange) {
            onChange('');
        }
    };

    const hasValue = value !== undefined && value !== null && value !== '';

    return (
        <div className={styles.inputContainer}>
            {label && <label className={styles.label}>{label}</label>}
            <div className={styles.inputWrapper}>
                <input
                    className={classNames(styles.input, className, showClearButton && styles.withClearButton)}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    value={value||(defaultValue?undefined:"")}
                    onChange={(e) => onChange && onChange(type === 'number' ? Number(e.target.value) : e.target.value)}
                    maxLength={maxLength}
                    type={type || 'text'}
                    required={required}
                    spellCheck={spellCheck}
                    autoCorrect={autoCorrect}
                    autoCapitalize={autoCapitalize}
                />
                {showClearButton && hasValue && (
                    <button
                        type="button"
                        className={styles.clearButton}
                        onClick={handleClear}
                        aria-label="Clear input"
                    >
                        <FontAwesomeIcon icon={faBackspace} style={{ fontSize: '2.2em', color: 'var(--primary-color)' }} />
                    </button>
                )}
            </div>
        </div>
    );
};