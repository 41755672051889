import React, {useMemo, useState, useEffect, useRef} from 'react';
import styles from './header_desktop.module.scss'
import {IPageProps} from "../page.props";
import {useNavigate, useLocation} from "react-router-dom";
import {GroupAdminRoles} from "../../util/roles";
import {Select} from "../form";
import {useParamOrg, useParamRole} from "../useParam";
import {Menu} from "./menu";
import {ProfileService} from "../../api";
import {ReactChildrenType} from "../../core/children";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIdCard} from "@fortawesome/free-solid-svg-icons";
import { Button } from "../form";

interface IHeaderDesktopProps extends IPageProps {
    menu: ReactChildrenType;
    hasSelectedOrg?: boolean;
}
// header_desktop.tsx
export const HeaderDesktop: React.FC<IHeaderDesktopProps> = ({user, profile, menu, hasSelectedOrg}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const role = useParamRole(user);
    const org = useParamOrg();
    const [hasAuthorized, setHasAuthorized] = useState(false);
    const isProfilePage = location.pathname.endsWith('profile');
    const defaultRoleAttempted = useRef(false);

    const adminRoles = useMemo(() => GroupAdminRoles(user), [user]);
    const orgRoles = useMemo(() =>
            org ? adminRoles[Object.keys(adminRoles).find(x => adminRoles[x]?.find(y => y.orgId === org)) || ''] || [] : [],
        [adminRoles, org, user]
    );

    const sortedOptions = useMemo(() => {
        if (!adminRoles) return [];

        return Object.values(adminRoles)
            .filter(roles => Array.isArray(roles))
            .reduce((acc, roles) => {
                roles.forEach(role => {
                    if (role && !acc.some(r => r.orgId === role.orgId)) {
                        acc.push(role);
                    }
                });
                return acc;
            }, [])
            .sort((a, b) => {
                if (a.orgLevel === b.orgLevel) {
                    return a.orgName.localeCompare(b.orgName);
                }
                return a.orgLevel - b.orgLevel;
            })
            .map(org => ({
                label: org.orgName + ' / ' + org.orgId,
                value: org.orgId
            }));
    }, [adminRoles]);

    const disabled = orgRoles.length === 0;
    const handleBack = () => {
        //navigate(-1); // Go back to previous page
        navigate('/');
    };
    const authorizeRole = async (funkMg: string) => {
        const form = await ProfileService.getForm(org, Number(funkMg));
        setHasAuthorized(true);
        navigate(`/${org}/${funkMg}/${form}`);
    }

    // Set default role if form equals "members" in current org and no role is currently selected
    useEffect(() => {
        const setDefaultRole = async () => {
            // Only proceed if we have an org selected, no current role, have available roles,
            // and haven't attempted to set default role yet
            if (org &&
                !role?.funkMgId &&
                orgRoles.length > 0 &&
                !hasAuthorized &&
                !defaultRoleAttempted.current) {

                defaultRoleAttempted.current = true; // Mark that we've attempted to set default
                console.log('Checking for role with form "members" in', orgRoles);

                // Check each role to find one that results in form "members"
                for (const possibleRole of orgRoles) {
                    try {
                        const form = await ProfileService.getForm(org, Number(possibleRole.funkMgId));
                        console.log(`Role ${possibleRole.funkMgId} has form: ${form}`);

                        if (form === "members") {
                            console.log('Found role with form "members":', possibleRole);
                            await authorizeRole(possibleRole.funkMgId.toString());
                            break;
                        }
                    } catch (error) {
                        console.error(`Error checking form for role ${possibleRole.funkMgId}:`, error);
                    }
                }

                // If we get here without authorizing, we didn't find a matching role
                if (!hasAuthorized) {
                    console.log('No role with form "members" found');
                    defaultRoleAttempted.current = false; // Reset so we can try again if dependencies change
                }
            }
        };

        setDefaultRole();
    }, [org, orgRoles, role, hasAuthorized]);

    // Reset the attempted flag when org changes
    useEffect(() => {
        defaultRoleAttempted.current = false;
    }, [org]);

    return (
        <div className={styles.header}>
            <div className={styles.item}>
                {/* Show org selector only after authorization */}
                {isProfilePage ? (
                    <Button
                        label="Zurück"
                        onClick={handleBack}
                        width="standard"
                    />
                ) : (
                    !isProfilePage && hasSelectedOrg && sortedOptions.length > 0 && (
                        <Select
                            placeholder={'Wähle deinen Verein/Verband'}
                            type={'number'}
                            value={org}
                            className={styles.select}
                            onChange={(id) => navigate(`/${id}`)}
                            options={sortedOptions}
                        />
                    )
                )}
            </div>
            <div className={styles.item}>
                {(!isProfilePage && org && !disabled) ? ( // Only show Form selector when org is selected
                    <Select
                        placeholder={'wähle Formular'}
                        value={role?.funkMgId}
                        className={styles.select}
                        onChange={authorizeRole}
                        options={orgRoles?.sort((a, b) => {
                            const numA = parseInt(a.functionName.replace(/^F/, ''), 10);
                            const numB = parseInt(b.functionName.replace(/^F/, ''), 10);
                            return numA - numB;
                        }).map(x => ({
                            label: x.functionName,
                            value: x.funkMgId,
                        }))}
                        disabled={disabled}
                    />
                ) : null}
            </div>
            {user?.familyName &&
                <Menu user={user}>
                    {menu}
                </Menu>
            }
            <div className={styles.logo} />
        </div>
    );
};